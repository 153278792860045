import * as React from "react"
import CssBaseline from "@mui/material/CssBaseline"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Container from "@mui/material/Container"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { styled, alpha } from "@mui/material/styles"
var slugify = require("slugify")
import { navigate } from "gatsby"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Paper from "@mui/material/Paper"
import List from "@mui/material/List"
import SearchIcon from "@mui/icons-material/Search"
import InputBase from "@mui/material/InputBase"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import HomeIcon from "@mui/icons-material/Home"
import ViewComfyIcon from "@mui/icons-material/ViewComfy"
import IconButton from "@mui/material/IconButton"
import "./ViewAnime.css"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"
import { Link } from "gatsby"
import { Anime } from "../types/AnimeTypes"

const theme = createTheme()

function calculateText(
  subbed: boolean,
  dubbed: boolean,
  mature: boolean
): string {
  let text = ""
  if (dubbed && subbed && mature) {
    text = "Mature, Subbed & Dubbed."
  } else if (dubbed && subbed) {
    text = "Subbed & Dubbed."
  } else if (mature && (subbed || dubbed)) {
    text = "Mature, "
    if (subbed) {
      text = text + "Subbed."
    }
    if (dubbed) {
      text = text + "Dubbed."
    }
  } else if (mature) {
    text = "Mature."
  } else if (subbed) {
    text = "Subbed."
  } else if (dubbed) {
    text = "Dubbed."
  }
  return text
}

export default function ViewAnimePage({ pageContext }) {
  let anime: Anime = pageContext.anime
  let CrunchyrollText = calculateText(
    anime.Crunchyroll?.IsSubbed,
    anime.Crunchyroll?.IsDubbed,
    anime.Crunchyroll?.Mature
  )
  let VRVText = calculateText(
    anime.VRV?.IsSubbed,
    anime.VRV?.IsDubbed,
    anime.VRV?.Mature
  )
  let WhereYouCanWatch = ""
  if (
    anime.Crunchyroll?.Link != undefined &&
    anime.VRV?.Link != undefined &&
    anime.Funimation?.Link != undefined
  ) {
    WhereYouCanWatch = "Crunchyroll, Funimation & VRV!"
  } else if (
    anime.Crunchyroll?.Link != undefined &&
    anime.VRV?.Link != undefined
  ) {
    WhereYouCanWatch = "Crunchyroll & VRV!"
  } else if (
    anime.Funimation?.Link != undefined &&
    anime.VRV?.Link != undefined
  ) {
    WhereYouCanWatch = "Funimation & VRV!"
  } else if (
    anime.Funimation?.Link != undefined &&
    anime.Crunchyroll?.Link != undefined
  ) {
    WhereYouCanWatch = "Funimation & Crunchyroll!"
  } else if (anime.VRV?.Link != undefined) {
    WhereYouCanWatch = "VRV!"
  } else if (anime.Crunchyroll?.Link != undefined) {
    WhereYouCanWatch = "Crunchyroll!"
  } else if (anime.Funimation?.Link != undefined) {
    WhereYouCanWatch = "Funimation!"
  }

  return (
    <ThemeProvider theme={theme}>
      <Seo
        description={`Where can I watch ${anime.Title}? You can watch ${anime.Title} on ${WhereYouCanWatch}. Links inside!`}
        title={`Where can I watch ${anime.Title}?`}
        keywords={`Where can I watch ${anime.Title}? You can watch ${anime.Title} on ${WhereYouCanWatch}`}
        image={anime.MiniImage}
      />
      <main>
      <Container component="main" className="mainAnimeBox">
        <CssBaseline />
        <article>
        <Paper elevation={3} className="textSpacerPadding">
          <h1 className="h1-text-size">Where can I watch {anime.Title}?</h1>

          <Typography>
            You can watch {anime.Title} on {WhereYouCanWatch}
          </Typography>
          <Paper
            elevation={3}
            className="animeInfoCard textSpacerMargin"
            sx={{ float: "right", width: "25%" }}
          >
            <List>
              <div className="animeTitle" key="animeTitle">
                {anime.Title}
              </div>
              <Box
                component="img"
                sx={{
                  width: "100%",
                  height: "100%",
                }}
                alt={`${anime.Title} Cover Image`}
                src={anime.FullImage}
                className="animeImage"
                key="animeImage"
              />
            </List>
            <div className="flex">
              <p className="data-question">Is Mature?</p>{" "}
              <p className="data-field">
                {anime.GenericData?.isMature ? "Yes" : "No"}
              </p>{" "}
              <br />
              {anime.GenericData.Categories.length == 0 ? null : (
                <React.Fragment>
                  <p className="data-question">Categories:</p>{" "}
                  <p className="data-field">
                    {anime.GenericData.Categories.join(", ")}
                  </p>
                </React.Fragment>
              )}
            </div>
          </Paper>
          {anime.Crunchyroll == undefined ? null : (
            <a href={anime.Crunchyroll.Link}>
              <h2> Watch on Crunchyroll! {CrunchyrollText} </h2>
              <StaticImage
                src="../images/crunchy.png"
                width={100}
                height={100}
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="Crunchyroll logo"
                style={{ marginBottom: `1.45rem` }}
              />
            </a>
          )}
          {anime.Funimation == undefined ? null : (
            <a href={anime.Funimation.Link}>
              <h2> Watch on Funimation!</h2>
              <StaticImage
                src="../images/funimation.jpeg"
                width={100}
                height={100}
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="Funimation logo"
                style={{ marginBottom: `1.45rem` }}
              />
            </a>
          )}
          {anime.VRV == undefined ? null : (
            <a href={anime.VRV.Link}>
              <h2> Watch on VRV! {VRVText}</h2>
              <StaticImage
                src="../images/vrv.png"
                width={100}
                height={100}
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="VRV logo"
                style={{ marginBottom: `1.45rem` }}
              />
            </a>
          )}
          <br />
          <br />
          <Typography variant="h4">Anime Description:</Typography>
          <Typography>{anime.Description}</Typography>
        </Paper>
        </article>
        <div className="suggestionsFlex">
          {pageContext.suggestions?.map((suggestion: Anime) => {
            return (
              <Link
                to={
                  "/" +
                  (suggestion.GenericData.Slug == undefined ||
                  suggestion.GenericData.Slug == ""
                    ? slugify(suggestion.Title, { strict: true })
                    : suggestion.GenericData.Slug)
                }
              >
                <Card sx={{ maxWidth: 345 }} className="suggestionsFlexItem">
                  <CardMedia
                    component="img"
                    image={suggestion.MiniImage}
                    alt={`${suggestion.Title} Cover Image`}
                    className="suggestionImage"
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className="suggestionsText"
                    >
                      {suggestion.Title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      className="suggestionsText"
                    >
                      {suggestion.Description}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            )
          })}
        </div>
      </Container>
      </main>
    </ThemeProvider>
  )
}

